/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.d-flex[data-v-4a05d15f] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text-right[data-v-4a05d15f] {
  text-align: right;
}
.v-popover[data-v-4a05d15f] {
  display: inline-block;
}
.max-height[data-v-4a05d15f] {
  max-height: 4.4rem;
}
.manager-name[data-v-4a05d15f] {
  width: 100%;
  font-size: 1.2rem;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 125%;
}
.manager-name.in-focus[data-v-4a05d15f] {
  position: relative;
}
.manager-name.in-focus[data-v-4a05d15f]::before {
  position: absolute;
  top: -0.2rem;
  left: 0;
  display: block;
  content: "";
  height: 4.4rem;
  width: 0.3rem;
  background-color: #e93f6f;
}
.name-heading-1[data-v-4a05d15f] {
  height: 4.4rem;
}
.total[data-v-4a05d15f] {
  font-weight: 600;
}
.manager[data-v-4a05d15f] {
  position: relative;
}